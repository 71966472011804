<template>
  <v-layout v-if="!isLoaded" column fill-height align-center justify-center>
    <AppErrorCard
      v-if="hasError"
      entity="scorecard"
      :is-anonymous-user="loggedInUser.isAnonymous"
      :is-invited="!!$route.query.invited"
      :invitee-email="$route.query.email"
      :show-auth-loader="showAuthLoader"
      @reload="reloadScorecardDetails"
      @login-with-google="$emit('login-with-google')"
      @login-with-microsoft="$emit('login-with-microsoft')"
      @login-with-email-pass="$emit('login-with-email-pass', $event)"
      @signup-with-email-pass="$emit('signup-with-email-pass', $event)"
      @reset-password="$emit('reset-password', $event)"
      @update-password="$emit('update-password', $event)"
    />

    <AppLoader v-else />
  </v-layout>
  <div v-else>
    <EOSScorecard
      ref="eosScorecard"
      title
      :initial-view="scorecardObject.data.initialView"
      :initial-metrics="scorecardObject.data.metrics"
      :initial-monthly-metrics="scorecardObject.data.monthlyMetrics"
      :initial-score-dates="scorecardObject.data.scoreDates"
      :initial-hidden-columns="scorecardObject.hiddenColumns"
      @metric-update="updateMetrics"
      @change-view="saveScorecard"
      @set-message="setMessage"
    >

      <template v-if="scorecardObject.meta.name">
        <div
          v-if="!isEditingScorecardName"
          slot="left"
          class="d-block text-subtitle-2 text-sm-h6 cursor-pointer ml-1 py-1"
          @click="editScorecardName"
        >
          {{ scorecardObject.meta.name }}
        </div>
        <form v-else slot="left" @submit.prevent="onSubmitScorecardName">
          <input
            ref="scorecardNameInput"
            v-model="scorecardObject.meta.name"
            type="text"
            size="30"
            class="text-subtitle-2 text-sm-h6 px-2 py-1"
            @change="saveScorecardName"
            @blur="onSubmitScorecardName"
          />
        </form>
      </template>

      <div slot="right" class="d-flex mr-n2">
        <AppVisibilityBtn
          class="mt-2"
          entity="Scorecard"
          :visibility="scorecardObject.meta.visibility"
          @click="togglePrivacy"
        />

        <AppUserPermissionList
          entity="Scorecard"
          :entity-name="scorecardObject.meta.name"
          :logged-in-user="loggedInUser"
          :visibility="scorecardObject.meta.visibility"
          :domains="scorecardObject.meta.permissions.domains"
          :users="scorecardObject.data.users"
          @make-private="togglePrivacy"
          @update-permissions="updatePermissions"
          @set-message="setMessage"
        />

        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense min-width="165px">
            <v-list-item @click.stop="showDialogConfirmDelete = true">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="openImportExportModal">
              <v-list-item-title>Import/Export Data</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </EOSScorecard>

    <AppDialogJSONImportExport
      v-if="showImportExportModal"
      title="Scorecard Data"
      allow-update
      :dataJSON="dataJSON"
      :show-loader="isUpdatingScorecardJSON"
      @update-data="updateScorecardJSON"
      @close="closeImportExportModal"
    />

    <AppDialogConfirmDelete
      v-if="showDialogConfirmDelete"
      message="You want to delete this scorecard?"
      @confirm="deleteScorecard"
      @cancel="showDialogConfirmDelete = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppVisibilityBtn from '@/components/shared/visibility/AppVisibilityBtn'
import AppUserPermissionList from '@/components/shared/permissions/AppUserPermissionList'
import AppDialogJSONImportExport from '@/components/shared/dialogs/AppDialogJSONImportExport'
import AppErrorCard from '@/components/shared/errors/AppErrorCard'
import EOSScorecard from '@/components/eos/EOSScorecard'

import DataManager from '@/helpers/dataManager'
import FIREBASE_PERMISSION_ERROR_CODES from '@/enums/firebasePermissionErrorCodes'

export default {
  name: 'PageScorecard',
  components: {
    AppVisibilityBtn,
    AppUserPermissionList,
    AppDialogJSONImportExport,
    AppErrorCard,
    EOSScorecard
  },
  metaInfo () {
    return {
      title: 'Scorecard'
    }
  },
  props: {
    showAuthLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataManager: new DataManager(),
      isLoaded: false,
      hasError: false,
      scorecardId: null,
      scorecardObject: {
        meta: {
          name: '',
          visibility: 'public',
          permissions: {
            domains: []
          }
        },
        data: {
          users: [],
          metrics: [],
          monthlyMetrics: [],
          scoreDates: [],
          hiddenColumns: {
            weekly: [],
            monthly: []
          },
          initialView: ''
        }
      },
      isEditingScorecardName: false,
      showImportExportModal: false,
      isUpdatingScorecardJSON: false,
      dataJSON: '',
      showDialogConfirmDelete: false
    }
  },
  computed: {
    ...mapGetters(['loggedInUser'])
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.loadScorecardDetailsById(to.params.scorecardId)
      }
    }
  },
  destroyed () {
    // destroy will prevent memory leaks
    this.dataManager.destroy()
  },
  methods: {
    ...mapActions(['setMessage']),

    loadScorecardDetailsById (scorecardId) {
      this.scorecardId = null // will stop overwriting saves while we load
      this.dataManager.cancelSubscriptions()

      const handlerError = (error) => {
        this.hasError = true
        this.setMessage({ type: 'error', message: error.message })
        // remove from list if do not have access
        if (FIREBASE_PERMISSION_ERROR_CODES.includes(error.code)) {
          this.dataManager.updateUserReferenceToObject('scorecards', scorecardId)
        }
      }

      try {
        this.dataManager.syncObject('scorecards', scorecardId, (error, scorecardObject) => {
          if (error) return handlerError(error)

          this.scorecardId = scorecardId
          scorecardObject.meta = {
            ...this.scorecardObject.meta,
            ...scorecardObject.meta
          }
          this.scorecardObject = { ...this.scorecardObject, ...scorecardObject }

          this.isLoaded ? this.$refs.eosScorecard.metrics = scorecardObject.data.metrics : this.isLoaded = true
          this.hasError = false
        })
      } catch (error) {
        handlerError(error)
      }
    },

    reloadScorecardDetails () {
      this.loadScorecardDetailsById(this.$route.params.scorecardId)
    },

    updateMetrics () {
      if (this.isLoaded) this.saveScorecard()
    },

    saveScorecard () {
      if (!this.isLoaded || this.scorecardId === null || !this.loggedInUser) return

      // get the data from the scorecard
      const eosScorecard = this.$refs.eosScorecard
      if (eosScorecard == null || typeof eosScorecard !== 'object') {
        return this.setMessage({
          type: 'error',
          message: `Error saving scorecard: Scorecard is ${typeof eosScorecard}`
        })
      }

      return this.dataManager.saveObject('scorecards', this.scorecardId, {
        meta: this.scorecardObject.meta,
        data: {
          initialView: eosScorecard.currentView, // weekly/monthly
          metrics: eosScorecard.metrics,
          monthlyMetrics: eosScorecard.monthlyMetrics,
          scoreDates: eosScorecard.scoreDates,
          hiddenColumns: eosScorecard.hiddenColumns,
          users: this.scorecardObject.data.users
        }
      })
    },

    async editScorecardName () {
      this.isEditingScorecardName = true
      await this.$nextTick()
      this.$refs.scorecardNameInput.focus()
    },

    saveScorecardName () {
      try {
        this.saveScorecard()
        // We need to also update the name in the list
        this.dataManager.updateObjectNameInList('scorecards', this.scorecardId, this.scorecardObject.meta.name)
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    onSubmitScorecardName () {
      this.isEditingScorecardName = false
    },

    async deleteScorecard () {
      try {
        this.showDialogConfirmDelete = false
        this.isLoaded = false
        await this.dataManager.updateUserReferenceToObject('scorecards', this.scorecardId)
        this.$router.push({ name: 'home' })
      } catch (error) {
        this.isLoaded = true
        this.setMessage({ type: 'error', message: `Error deleting scorecard: ${error.message}` })
      }
    },

    togglePrivacy () {
      if (this.loggedInUser.isAnonymous) {
        return this.setMessage({
          type: 'error',
          message: 'Please sign in to make this scorecard private.'
        })
      }
      const visibility = this.scorecardObject.meta.visibility
      const newVisibility = visibility === 'private' ? 'public' : 'private'
      this.scorecardObject.meta.visibility = newVisibility
      this.saveScorecard()
      this.$analytics('scorecard_changed_privacy', { type: newVisibility })
    },

    updatePermissions ({ domains, usersMeta, users }) {
      this.$set(this.scorecardObject.meta, 'permissions', { domains, ...usersMeta })
      this.$set(this.scorecardObject.data, 'users', users)

      this.dataManager.updateObject('scorecards', this.scorecardId, {
        'meta.permissions': this.scorecardObject.meta.permissions,
        'data.users': this.scorecardObject.data.users
      })
    },

    openImportExportModal () {
      this.dataJSON = JSON.stringify(this.$refs.eosScorecard.metrics, null, 4)
      this.showImportExportModal = true
    },

    async updateScorecardJSON (json) {
      try {
        this.isUpdatingScorecardJSON = true
        this.$refs.eosScorecard.metrics = JSON.parse(json)
        await this.saveScorecard()
        this.closeImportExportModal()
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating data: ${error.message}` })
      } finally {
        this.isUpdatingScorecardJSON = false
      }
    },

    closeImportExportModal () {
      this.showImportExportModal = false
    }
  }
}
</script>

<style lang="scss">
body {
  overflow-y: hidden;
}
</style>
